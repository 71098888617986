import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { isNil, evolve, map, pipe, pick } from "ramda";

import CollectionEditor from "../../../../lib/CollectionEditor";
import { Context as SnackbarContext } from "../../../../lib/Snackbar";
import Card from "./Card";
import VideoCard from "./VideoCard";
import EditForm from "./EditForm";
import VideoForm from "./VideoForm";
import { setSlidesMutation } from "./gql";
import * as UI from "./ui";

export const Context = React.createContext(); 

const buildVideoPayload = pipe(
  pick(['caption', 'video']),
  evolve({
    video: pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  }),
);

const buildPayload = map(
  pipe(
    pick(['caption', 'image']),
    evolve({
      image: pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
    }),
  )
);

const SlideEditor = ({ sweepstakes, onStepFinish }) => {
  const { addMessage } = useContext(SnackbarContext);
  const { slides, slideVideo } = sweepstakes;
  const [ setSlides ] = useMutation(setSlidesMutation);

  const handleSubmit = ([videos, slides]) => {
    const slideVideo = videos[0];
    const payload = buildPayload(slides);
    setSlides({
      variables: {
        sweepstakesId: sweepstakes.id,
        slides: payload,
        video: slideVideo ? buildVideoPayload(slideVideo) : null,
      }
    }).then(({ data }) => {
      if(isNil(data.setSlides.error)) {
        addMessage({ message: "Your slides have been updated.", type: "success" });
        onStepFinish();
        return;
      }
      addMessage({ message: data.setSlides.error.message, type: "error" });
    });
  };
  const slideVideoList = slideVideo ? [slideVideo] : [];

  const itemList = [
    slideVideoList,
    slides,
  ]

  return (
    <UI.Layout>
      <UI.Instructions>
        <p>Add up to <strong>one prize video</strong> and <strong>12 prize images</strong> (.jpg or .png) to your slide show. Click the corresponding “Add New” button and upload, or drag and drop your media into the box.</p>
        <p><strong>Video:</strong> The video is limted to 30 seconds and will always be the first slide. It will be sized and updated to an optimal format for your sweepstakes site.</p>
        <p><strong>Images:</strong> Images will be sized and cropped automatically. You can chose to remove cropping, which will add letterboxing to pad your image.</p>

        After uploading your slide you must write a caption then <strong>click the “Done” button to save</strong>. You may change the order by dragging a slide into position. <strong>Click the “Publish Changes” button to go live with your changes.</strong>
        <p><strong>Note:</strong> you can update your slides with a new video or new photos or captions at any point during the sweepstakes.</p>
      </UI.Instructions>
      <Context.Provider value={{ sweepstakes }}>
        <CollectionEditor
          items={itemList}
          onSubmit={handleSubmit}
          draftNotice="You have unpublished changes."
          discardDraftLabel="Discard Changes"
          submitLabel="Publish Changes"
          draftKey={`SlideEditor:${sweepstakes.id}`}
          itemConfigs={[
            { newItemLabel: "Add New Video", itemLabel: "Slide Video", ItemComponent: VideoCard, EditorComponent: VideoForm, minItems: 0, maxItems: 1, disableDnd: true },
            { newItemLabel: "Add New Image", itemLabel: "Slide Image", ItemComponent: Card, EditorComponent: EditForm, minItems: 1, maxItems: 12 },
          ]}
        />
      </Context.Provider>
    </UI.Layout>
  );
};

export default SlideEditor;
