import { Cloudinary } from "@cloudinary/url-gen";
import { Resize } from "@cloudinary/url-gen/actions";

export const buildCldPoster = (source) => new Cloudinary({
  cloud: {
    cloudName: source.cloudName,
  },
})
  .video(`${source.publicId}.webp`)
  .setVersion(source.version)
  .resize(Resize.scale().width(880))
  .format('webp')


const buildMp4 = (source) => new Cloudinary({
  cloud: {
    cloudName: source.cloudName,
  },
})
  .video(`${source.publicId}.mp4`)
  .setVersion(source.version)
  .resize(Resize.limitFit().width(1920).height(1080))
  .addTransformation("vc_h264")
  .format("mp4")

const buildWebm = (source) => new Cloudinary({
  cloud: {
    cloudName: source.cloudName,
  },
})
  .video(`${source.publicId}.webm`)
  .setVersion(source.version)
  .resize(Resize.limitFit().width(1920).height(1080))
  .addTransformation("vc_vp9")
  .format("webm")

export const buildVideoSources = source => ([
  {
      type: 'video/webm',
      src: buildWebm(source).toURL(),
  },
  {
      type: 'video/mp4',
      src: buildMp4(source).toURL(),
  },
]);
