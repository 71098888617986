import React from "react";
import { isNil, pipe, pick, evolve } from "ramda";

import Form from "./Form";
import * as UI from "./ui";

const CROP_OPTIONS = [
  {
    id: 'fill',
    name: 'Crop to Fit',
    strategy: {
      crop: 'fill',
      background: null,
    },
  },
  {
    id: 'lpad',
    name: 'No Crop',
    strategy: {
      crop: 'lpad',
      background: 'auto',
    }
  },
];

const evolveImageType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['id', 'image', 'caption']),
  evolve({
    image: evolveImageType,
  })
);

const EditForm = ({ onSelect, onDone, item }) => {
  if(isNil(item)) {
    return null;
  }

  return (
    <UI.EditorLayout>
      <Form
        id="image"
        label="Slide Image"
        initialInputObject={buildInitialInputObject(item)}
        onCancel={() => onSelect(null)}
        onSubmit={onDone}
        type="image"
        cropOptions={CROP_OPTIONS}
      />
    </UI.EditorLayout>
  );
}

export default EditForm;
