import React from "react";
import { DndContext, closestCenter, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import Item from "./Item";
import * as UI from "./ui";

const Collection = ({ items, handleDragEnd, onSelect, onRemove, removeEnabled, itemLabel, ItemComponent, disableDnd }) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      }
    }),
    useSensor(TouchSensor)
  );
  const itemCountDisabled = items.length === 1;

  const collection = (
    <UI.CollectionLayout>
      {items.map((item, index) => (
        <Item
          key={item.id}
          item={item}
          index={index}
          itemLabel={itemLabel}
          onSelect={onSelect}
          onRemove={onRemove}
          removeEnabled={removeEnabled}
          ItemComponent={ItemComponent}
          disableDnd={disableDnd}
          itemCountDisabled={itemCountDisabled}
        />
      ))}
    </UI.CollectionLayout>
  )

  if (disableDnd) {
    return collection;
  }

  return (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {collection}
      </SortableContext>
    </DndContext>
  );
}
export default Collection;
