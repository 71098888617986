import React from "react";
import { faLock, faCheckCircle, faCircleDot, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as UI from "./ui";

const NOOP = () => false;

const OverviewItem = ({ step, ...rest }) => {
  const isLocked = step.isLocked || NOOP;
  const locked = isLocked(rest);
  const stepIcon = step.optional ? faCircleDot : faCheckCircle;
  const isInvalid = step.isInvalid && step.isInvalid(rest);

  return (
    <UI.Step key={step.id} to={step.id} data-intercom-target={`${step.id}*`}>
      <UI.Info>
        <UI.Name>
          {step.name} {locked && <FontAwesomeIcon icon={faLock} />}
        </UI.Name>
        <UI.Description>{step.description}</UI.Description>
      </UI.Info>
      <UI.Icon $isComplete={step.isComplete(rest)} $isInvalid={isInvalid}>
        <FontAwesomeIcon icon={isInvalid ? faCircleXmark : stepIcon} />
      </UI.Icon>
    </UI.Step>
  )
};

const Overview = ({ steps, heading, ...rest}) => (
  <React.Fragment>
    { heading && <UI.OverviewHeading>{heading}</UI.OverviewHeading> }
    <UI.Layout>
      { steps.map(step => <OverviewItem key={step.id} step={step} {...rest} /> )}
    </UI.Layout>
  </React.Fragment>
);

export default Overview;
