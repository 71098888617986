import React, { useContext, useRef, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Context as SnackbarContext } from "../../../lib/Snackbar";
import { propOr } from "ramda";

import { nonprofitSearchQuery } from "./gql";

import { isBlank, isPresent } from "../../../util/presence";

import * as UI from "./ui";

const NonprofitSearch = ({ setNonprofit, setMode }) => {
  const [ getNonprofitSearchQuery, { called, data, loading, error } ] = useLazyQuery(nonprofitSearchQuery);
  const [showResults, setShowResults] = useState(false);
  const searchTimeout = useRef(null);
  const { addMessage } = useContext(SnackbarContext);

  useEffect(() => {
    return () => clearTimeout(searchTimeout.current);
  }, []);

  const search = propOr(null, "nonprofitSearch", data);
  const results = propOr([], "results", search);

  const resultsPresent = (!loading || error) && showResults && isPresent(results);
  const resultsEmpty = called && !loading && (isBlank(results) || error);

  const fetchNonprofits = (e) => {
    e.preventDefault();

    if (searchTimeout.current || e.target.value.length < 1) {
      clearTimeout(searchTimeout.current);
    }

    if (e.target.value.length < 1) {
      setShowResults(false);
      return;
    }

    searchTimeout.current = setTimeout(() => {
      setShowResults(true);
      getNonprofitSearchQuery({ variables: { term: e.target.value } }).then(({ data }) => {
        const responseError = propOr(null, "error", data.nonprofitSearch);

        if (isBlank(responseError)) {
          return;
        }

        addMessage({ type: "error", message: "Something went wrong with your search. You can try again or enter your information manually." });
      });
    }, 300);
  }

  const handleSelect = result => () => {
    setNonprofit(result);
    setMode("form");
  }

  return (
    <UI.Layout>
      <UI.Input type="text" onChange={fetchNonprofits} placeholder="Search for your organization by name or EIN" />
      {resultsPresent && (
        <UI.ResultsLayout>
          {results.map(result => (
            <UI.Result key={result.ein} onClick={handleSelect(result)}>
              <UI.Title>{result.name}</UI.Title>
              <UI.Subtitle>{result.ein}</UI.Subtitle>
            </UI.Result>
          ))}
        </UI.ResultsLayout>
      )}
      {resultsEmpty && (
        <UI.EmptyState>
          <UI.Link onClick={() => setMode("form")}>Enter your information manually</UI.Link>
        </UI.EmptyState>
      )}
    </UI.Layout>
  );
};

export default NonprofitSearch;
