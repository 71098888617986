import { gql } from "@apollo/client";

export const nonprofitSearchQuery = gql`
  query NonprofitSearchQuery($term: String!) {
    nonprofitSearch(term: $term) {
      size
      from
      term
      resultCount
      error {
        status
        message
      }
      results {
        name
        ein
        organizationUrl
        charityNavigatorUrl
        cause
        mission
        highestLevelAdvisory
        street
        street2
        city
        state
        zip
        country
      }
    }
  }
`;
