import React from "react";
import { isNil, pipe, pick, evolve } from "ramda";

import Form from "./Form";
import * as UI from "./ui";

const evolveVideoType = value => isNil(value) ? value : pipe(
  pick(['cloudName', 'publicId', 'version', 'crop', 'gravity', 'background']),
  JSON.stringify,
)(value);

const buildInitialInputObject = pipe(
  pick(['id', 'video', 'caption']),
  evolve({
    video: evolveVideoType,
  })
);

const VideoForm = ({ onSelect, onDone, item }) => {
  if(isNil(item)) {
    return null;
  }

  return (
    <UI.EditorLayout>
      <Form
        id="video"
        label="Slide Video"
        initialInputObject={buildInitialInputObject(item)}
        onCancel={() => onSelect(null)}
        onSubmit={onDone}
        type="video"
        cropOptions={[]}
      />
    </UI.EditorLayout>
  );
}

export default VideoForm;
