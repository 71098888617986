import React, { useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { isNil, map, path, pathOr } from "ramda";

import Form from "../forms/Form";
import SelectField from "../forms/SelectField";
import Button from "../design/Button";
import { presence } from "../../lib/validations";
import { Context as SnackbarContext } from "../../lib/Snackbar";
import { isBlank } from "../../util/presence";

import * as UI from "./ui";
import { getPromoCodes, retroApplyPromoCodeMutation } from "./gql";

const buildPromoCodeOptions = map(({id, name, bonusPercent}) => ({
  id,
  name: `${name} - ${bonusPercent}%`
}));

const validations = {
  promoCodeId: presence,
};

const ApplyPromoCodeForm = ({ sweepstakes, entry, disabled }) => {
  const query = useQuery(getPromoCodes, {
    variables: {
      sweepstakesId: sweepstakes.id,
    }
  });
  const { addMessage } = useContext(SnackbarContext);

  const [ retroApplyPromoCode, mutation ] = useMutation(retroApplyPromoCodeMutation);

  const onSubmit = variables => {
    if(window.confirm("Are you sure you want to continue? A promo code cannot be removed or changed.")) {
      return retroApplyPromoCode({ variables }).then(({ data, error: platformError }) => {
        if (isNil(platformError) && isBlank(path(['retroApplyPromoCode', 'error'], data))) {
          addMessage({ type: "success", message: "Promo code applied and updated receipt emailed to Donor." })
        } else {
          addMessage({ type: "error", message: "There was a problem applying this promo code." })
        }
      });
    }
  }

  if(query.loading) {
    return null;
  }

  const promoCodes = pathOr([], ['data', 'sweepstakes', 'promoCodes'], query);

  return (
    <React.Fragment>
      <Form initialInputObject={{ entryId: entry.id }} onSubmit={onSubmit} validations={validations}>
        <UI.InlineFormLayout>
          <SelectField id="promoCodeId" label="Select a Promo Code to Apply" options={buildPromoCodeOptions(promoCodes)} allowBlank disabled={disabled} />
          <Button type="submit" size="tiny" disabled={mutation.loading || disabled}>Apply & Resend Receipt</Button>
        </UI.InlineFormLayout>
      </Form>
    </React.Fragment>
  );
}

export default ApplyPromoCodeForm;
