import React, { useState, useMemo } from "react";
import { find, propEq, update } from "ramda";
import { arrayMove } from "@dnd-kit/sortable";
import { v4 as uuid } from 'uuid';

import { isPresent } from "../../util/presence";
import useDraftableState from "../useDraftableState";
import Header from "./Header";
import Collection from "./Collection";
import Editor from "./Editor";
import * as UI from "./ui";

export const NEW_ITEM = { id: "__NEW__" };

const findSelectedItem = (items, selectedItem) => {
  if (isPresent(selectedItem)) {
    const [offset, id] = selectedItem;
    return id === NEW_ITEM.id ? NEW_ITEM : find(propEq(id, 'id'), items[offset]);
  }

  return null;
}

const CollectionEditor = ({
  submitLabel,
  draftNotice,
  onSubmit,
  draftKey,
  discardDraftLabel,
  items: initialItems,
  itemConfigs,
}) => {
  const [ items, setItems, draft ] = useDraftableState(initialItems, draftKey);
  const [ selectedItemId, setSelectedItemId ] = useState(null);
  const selectedItem = findSelectedItem(items, selectedItemId)

  const handleDragEnd = offset => ({ active, over }) => {
    if (active.id !== over.id) {
      setItems(currentItems => {
        const items = currentItems[offset];
        const oldIndex = items.findIndex(propEq(active.id, 'id'));
        const newIndex = items.findIndex(propEq(over.id, 'id'));
        const newList = arrayMove(items, oldIndex, newIndex);
        return update(offset, newList, currentItems);
      });
    }
  }

  const appendItem = (item, offset) => {
    const newItem = { ...item, id: uuid() };
    setItems(currentItems => {
      const nextItems = [ ...currentItems[offset], newItem ]
      return update(offset, nextItems, currentItems);
    });

    setSelectedItemId(null);
  }

  const updateSelectedItem = (updatedItem, offset) => {
    const index = items[offset].findIndex(propEq(updatedItem.id, 'id'));
    setItems(currentItems => {
      const nextItems = [...currentItems[offset]];
      nextItems[index] = updatedItem;
      return update(offset, nextItems, currentItems);
    });

    setSelectedItemId(null);
  }

  const handleRemoval = offset => removedItem => {
    if(window.confirm("Are you sure you want to remove this?")) {
      const index = items[offset].findIndex(propEq(removedItem.id, 'id'));
      setItems(currentItems => {
        const nextItems = [...currentItems[offset]];
        nextItems.splice(index, 1);
        return update(offset, nextItems, currentItems);
      });
    }
  };

  const handleDone = offset => item => {
    item.id === NEW_ITEM.id ? appendItem(item, offset) : updateSelectedItem(item, offset)
  };

  const handleSubmit = () => {
    draft.clear();
    return onSubmit(items);
  }
  const handleSelection = offset => id => { setSelectedItemId([offset, id]); }

  const newItemLabels = useMemo(() => itemConfigs.map(({ newItemLabel }) => newItemLabel), [ itemConfigs ]);
  const newItemEnabled = useMemo(() =>
    items.map((item, i) => item.length < (itemConfigs[i].maxItems || Number.POSITIVE_INFINITY)),
    [ items, itemConfigs ],
  );
  const itemConfigLength = itemConfigs.length - itemConfigs.filter(c => c.length < 1).length;

  return (
    <UI.Layout rows={itemConfigLength} >
      { isPresent(newItemLabels) && (
        <Header
          newItemLabels={newItemLabels}
          newItemEnabledList={newItemEnabled}
          draftNotice={draftNotice}
          discardDraftLabel={discardDraftLabel}
          setSelectedItemId={setSelectedItemId}
          submitLabel={submitLabel}
          onSubmit={handleSubmit}
          draft={draft}
        />
      )}
      {items.map((itemList, i) => (
        <React.Fragment key={`CollectionInstance-${i}`}>
          {itemList.length > 0 && (
            <Collection
              items={itemList}
              handleDragEnd={handleDragEnd(i)}
              onSelect={handleSelection(i)}
              onRemove={handleRemoval(i)}
              removeEnabled={itemList.length > (itemConfigs[i].minItems || 0)}
              disableDnd={itemConfigs[i].disableDnd}
              itemLabel={itemConfigs[i].itemLabel}
              ItemComponent={itemConfigs[i].ItemComponent}
            />
          )}
          <Editor
            item={selectedItemId && selectedItemId[0] === i ? selectedItem : null}
            onSelect={handleSelection(i)}
            onDone={handleDone(i)}
            EditorComponent={itemConfigs[i].EditorComponent}
          />
        </React.Fragment>
      ))}
    </UI.Layout>
  );
};

export default CollectionEditor;
