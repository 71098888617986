import React from "react";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import DoNotRecord from "./DoNotRecord";
import Link from "../design/Link";
import Button from "../design/Button";
import { usePermissions } from "../../lib/permissions";
import CopyWinnerPresentationUrl from "./CopyWinnerPresentationUrl";

import * as UI from "./ui";

const Video = () => (
  <UI.VideoLayout>
    <iframe
      src="https://player.vimeo.com/video/1062465161?h=ffb1ab94c8&badge=0&autopause=0&player_id=0&app_id=58479"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
      title="How to Draw Your Sweepstakes Winner"
      style={{ width: "100%", height: "100%" }}
    >
    </iframe>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </UI.VideoLayout>
);

const IntroScreen = ({ sweepstakes, next, guarded }) => {
  const accessEnabled = usePermissions({ resource: "winners", permission: "C" });
  const hasWinner = !!sweepstakes.prize?.win;
  const enableNext = accessEnabled || hasWinner;

  const drawTime = sweepstakes.winningTicketDrawnAt ? (
    DateTime.fromISO(sweepstakes.winningTicketDrawnAt, { zone: sweepstakes.timezone }).toLocaleString(DateTime.DATETIME_SHORT)
  ) : (
    "TBD"
  );

  const messaging = guarded ? (
    <React.Fragment>
      <UI.GuardedDrawTime><span>Drawing Time:</span>&nbsp;<span>{drawTime}</span></UI.GuardedDrawTime>
      <p>This page will become active at your drawing time. Once the page is active, you will be guided step by step through the process of drawing your winner(s).</p>
      <Video />
    </React.Fragment>
  ) : (
    <p>These pages display the winner’s name and contact information. To protect your winner’s privacy, do not video, photograph or share screen captures of any screens in the Draw the Winner tab.</p>
  );

  const controls = guarded ? (
    <>
      <CopyWinnerPresentationUrl sweepstakes={sweepstakes} />
      <Link href="/downloads/winner_affidavit.pdf" size="small" icon={faFileDownload}>Download Winner Affidavit</Link>
    </>
  ) : (
    <Button type="submit" onClick={next} disabled={!enableNext}>Got it</Button>
  );

  return (
    <React.Fragment>
      <UI.StageContent>
        { !guarded && (
          <React.Fragment>
            {!hasWinner && (
              <UI.InstructionsLayout>
                <UI.StageHeading>
                  Yay! Its time to draw your winner.
                </UI.StageHeading>
              </UI.InstructionsLayout>
            )}
            <DoNotRecord /> 
          </React.Fragment>
        )}
        <UI.InstructionsLayout>
          {messaging}
        </UI.InstructionsLayout>
      </UI.StageContent>
      <UI.Controls>
        {controls}
      </UI.Controls>
    </React.Fragment>
  );
};

export default IntroScreen;
