import React from "react";
import Video from "../../../Video";

import * as UI from "./ui";

const VideoCard = ({ item }) => (
  <UI.CardLayout>
    <Video key={`VideoCard-${item.video.publicId}`} videoConfig={item.video} style={{width: "100%"}} posterOnly />
    <UI.Caption>{item.caption}</UI.Caption>
  </UI.CardLayout>
);

export default VideoCard;
