import styled from "styled-components";

export const Layout = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const DraftInfo = styled.div`
  opacity: ${props => props.enabled ? 1 : 0 };
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background-color: #fef0cd;
  border: 1px solid #ffe29b;
  transition: opacity 100ms linear;
  border-radius: 6px;
  gap: 12px;

  @media (max-width: 630px) {
    min-width: 100%;
    order: -1;
  }
`;

export const DraftNotice = styled.span`
  font-size: 0.75em;
`;

export const CollectionLayout = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-template-rows: repeat(auto-fill, 276px);
  overflow-y: auto;

  /* When 2 won't fit, shrink column and row size */
  @media (max-width: 756px) {
    grid-template-columns: repeat(auto-fill, 168px);
    grid-template-rows: repeat(auto-fill, 165px);
    gap: 6px;
  } 
`;

export const EditorLayout = styled.div`
  position: absolute;
  inset: 0;
  background-color: #ffffff;
  border-radius: 6px;
  z-index: 2;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;
  overflow-y: scroll;
  padding: 12px;

  ${props => props.enabled && `
    transform: translateY(0%);
  `}
`;

export const ItemLayout = styled.div`
  z-index: 0;

  ${props => props.$isDragging && `
    z-index: 1;
  `}
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
`;

export const Card = styled.div`
  position: relative;
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0);
  transition: box-shadow 100ms ease-in-out;

  &:hover {
    border-color: #aaaaaa;
  }

  ${props => props.$isDragging && `
    border-color: #aaaaaa;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  `}
`;

export const ItemContent = styled.div`
  flex: 1;
`;

export const Handle = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  height: 100%;
  ${props => props.$disabled ? 'visibility: hidden;' : ''}
`;

export const Grip = styled.div`
  width: 0;
  height: 0;
  margin: 1px;
  border-top: 1px solid rgba(100, 100, 100, 0.50);
  border-right: 1px solid rgba(255, 255, 255, 0.50);
  border-bottom: 1px solid rgba(255, 255, 255, 0.50);
  border-left: 1px solid rgba(25, 25, 25, 0.50);
`;

export const Index = styled.div`
  font-size: 0.75em;
  color: #666666;
  font-weight: bold;
  text-transform: uppercase;
  text-indent: 3px;
`;
