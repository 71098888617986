import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { REQUIRED_STEPS, OPTIONAL_STEPS } from "../SweepstakesEditPage/Editor/steps";
import * as UI from "./ui";

const Title = ({ children, plainText }) => (
  <React.Fragment>
    <Helmet>
      <title>{plainText || children} - TapKat Dashboard</title>
    </Helmet>
    <UI.Title>{children}</UI.Title>
  </React.Fragment>
);

const Heading = ({ title }) => (
  <UI.Heading>
    <Routes>
      <Route index                      element={<Title>Overview</Title>} />
      <Route path="donations"           element={<Title>Donations</Title>} />
      <Route path="promo-codes"         element={<Title>Promo Codes</Title>} />
      <Route path="winner"              element={<Title>Draw the Winner</Title>} />
      <Route path="insights"            element={<Title>Sweepstakes Insights</Title>} />
      <Route path="flash-contest"       element={<Title>Flash Contest</Title>} />
      <Route path="flash-contests"      element={<Title>Flash Contests</Title>} />
      <Route path="thank-you-campaigns" element={<Title>Thank You Campaigns</Title>} />
      <Route path="marketing"           element={<Title>Email Smart Schedule</Title>} />
      <Route path="plugin/*"            element={<Title>Donation Plugin</Title>} />
      <Route path="site"                element={<Title>Sweepstakes Setup</Title>} />
      <Route path="retro"               element={<Title>Retrospective Report</Title>} />

      { REQUIRED_STEPS.map(({ id, name }) => <Route key={id} path={`site/${id}`} element={<Title plainText={name}><Link to="../site">Setup</Link>{name}</Title>} />)}
      { OPTIONAL_STEPS.map(({ id, name }) => <Route key={id} path={`site/${id}`} element={<Title plainText={name}><Link to="../site">Setup</Link>{name}</Title>} />)}
    </Routes>
    <UI.Name to="../">{title}</UI.Name>
  </UI.Heading>
);

export default Heading;
