import axios from "axios";
import { identity, ifElse, pick, pipe, forEachObjIndexed } from "ramda";

const SIGNATURE_URL = `${process.env.REACT_APP_API_URL}/upload_signatures`;

const URL = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`;
const EAGER_VIDEO_TRANSCODES = [
  "c_limit,h_1080,w_1920/vc_vp9/f_webm",
  "c_limit,w_880/f_webp",
].join("|");
const INCOMING_VIDEO_TRANSCODE = "du_30/c_limit,h_1080,w_1920/vc_h264/f_mp4";

const CLOUDINARY_SIGNATURE_PARAMS = [
  'upload_preset',
  'timestamp',
  'folder',
  'transformation',
  'eager',
  'eager_async',
]

export const buildFormData = params => {
  const formData = new FormData();

  if (/video/.test(params.file.type)) {
    formData.append("eager", EAGER_VIDEO_TRANSCODES);
    formData.append("eager_async", true);
    formData.append("transformation", INCOMING_VIDEO_TRANSCODE);
  }

  forEachObjIndexed((value, key) => {
    formData.append(key, value);
  }, params);

  return formData;
}
const isVideo = params => /video/.test(params.file.type);

const addVideoTransformations = params =>
  Object.assign(params, {
    eager: EAGER_VIDEO_TRANSCODES,
    eager_async: true,
    transformation: INCOMING_VIDEO_TRANSCODE,
  });

const uploadSignatureParams = pipe(
  ifElse(isVideo, addVideoTransformations, identity),
  pick(CLOUDINARY_SIGNATURE_PARAMS),
)

export const generateSigningFunction = authToken => async params => {
  const upload_signature_params = uploadSignatureParams(params);

  return axios({
    url: SIGNATURE_URL,
    method: "POST",
    responseType: "text",
    data: { upload_signature_params },
    headers: { "Authorization": `Bearer ${authToken}` },
  });
}

export const upload = async (file, options) => {
  const {
    config = {},
    signUpload,
    onUploadProgress,
  } = options;

  const params = {
    ...config,
    api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
    upload_preset: 'assets',
    timestamp: Date.now(),
    file,
  };

  const { data: signature } = await signUpload(params);
  params['signature'] = signature;

  const formData = buildFormData(params);

  return axios({
    url: URL,
    method: 'POST',
    data: formData,
    onUploadProgress,
  });
};
