import { gql } from "@apollo/client";

export const setSlidesMutation = gql`
  mutation SetSlides($sweepstakesId: SweepstakesSlug!, $slides: [SlideInput!]!, $video: SlideVideoInput) {
    setSlides(sweepstakesId: $sweepstakesId, slides: $slides, video: $video) {
      sweepstakes {
        id
        slideVideo {
          id
          caption
          video {
            cloudName
            publicId
            version
            crop
            gravity
            background
          }
        }
        slides {
          id
          caption
          image {
            cloudName
            publicId
            version
            crop
            gravity
            background
          }
        }
        checklist {
          id
          slides
        }
      }
      error {
        code
        message
      }
    }
  }
`;
