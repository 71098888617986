import React, { useCallback, useContext } from "react";
import { dissoc, path } from "ramda";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { applyForAccountMutation } from "./gql";
import Form from "../forms/Form";
import Button from "../design/Button";
import TextField from "../forms/TextField";

import { isBlank, isPresent } from "../../util/presence";
import { Context as SnackbarContext } from "../../lib/Snackbar";
import { presence, formatOf, isLink } from "../../lib/validations";
import * as UI from "./ui";

const validations = {
  firstName: presence,
  lastName: presence,
  email: presence,
  nonprofitName: presence,
  ein: formatOf({
    pattern: /^\d{2}-?\d{7}$/,
    message: "This does not appear to be a valid EIN."
  }),
  website: isLink,
};

const removeTypename = dissoc("__typename");
const addScheme = url => {
  if (isBlank(url)) {
    return "https://";
  }

  return url.startsWith("http") ? url : `https://${url}`
}

const initialInputObject = selectedNonprofit => selectedNonprofit ?
  { website: addScheme(selectedNonprofit.organizationUrl), ein: selectedNonprofit.ein, nonprofitName: selectedNonprofit.name } :
  { website: "https://" }

const buildAddressFromSearch = nonprofitSearchDetails => {
  if (isBlank(nonprofitSearchDetails)) {
    return null;
  };

  return {
    line1: nonprofitSearchDetails.street,
    line2: nonprofitSearchDetails.street2,
    city: nonprofitSearchDetails.city,
    state: nonprofitSearchDetails.state,
    postalCode: nonprofitSearchDetails.zip,
    country: nonprofitSearchDetails.country,
  }
}

const Apply = ({ selectedNonprofit, clearNonprofit, setMode }) => {
  const [ applyForAccount, { loading } ] = useMutation(applyForAccountMutation);
  const { addMessage } = useContext(SnackbarContext);
  const navigate = useNavigate();

  const onSubmit = useCallback(attributes => {
    const nonprofitSearchDetails = removeTypename(selectedNonprofit);
    console.log(nonprofitSearchDetails);

    return applyForAccount({
        variables: {
          attributes: {
            nonprofitSearchDetails,
            nonprofitLegalName: nonprofitSearchDetails?.name,
            address: buildAddressFromSearch(nonprofitSearchDetails),
            ...attributes,
          }
        }
      }).then(({ data }) => {
        const accountApplicationId = path(['applyForAccount','accountApplication','id'], data);

        if (isPresent(accountApplicationId)) {
          navigate("success", { state: { accountApplicationId }})
        } else {
          addMessage({ type: "error", message: "There was a problem submitting your application, please try again" });
        }
      });
    }, [ selectedNonprofit, addMessage, applyForAccount, navigate ]);

  const hasSelectedNonprofit = isPresent(selectedNonprofit);

  const goBack = () => {
    clearNonprofit();
    setMode("search");
  };

  return (
    <React.Fragment>
      <Form initialInputObject={initialInputObject(selectedNonprofit)} onSubmit={onSubmit} validations={validations}>
        <UI.FieldGroup>
          <TextField id="firstName" label="First Name" />
          <TextField id="lastName" label="Last Name" />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <TextField id="email" type="email" label="Email Address" />
        </UI.FieldGroup>
        <UI.FieldGroup>
          <TextField id="nonprofitName" label="Nonprofit Organization" disabled={hasSelectedNonprofit && isPresent(selectedNonprofit.name)} />
          <TextField id="ein" label="Nonprofit EIN#" disabled={hasSelectedNonprofit && isPresent(selectedNonprofit.ein)} />
          <TextField id="website" label="Nonprofit Website" disabled={hasSelectedNonprofit && isPresent(selectedNonprofit.organizationUrl)} />
        </UI.FieldGroup>
        <UI.Legal>
          By clicking “Apply” or signing up you agree to <a href="https://tapkat.com/terms-of-service" target="_blank" rel="noopener noreferrer">TapKat Terms of Service</a>, <a href="https://www.tapkat.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, <a href="https://tapkat.com/copyright-policy" target="_blank" rel="noopener noreferrer">Copyright Policy</a> and <a href="https://tapkat.com/standards-of-conduct" target="_blank" rel="noopener noreferrer">Standards of Conduct</a>. You also agree to receive information and offers relevant to our services via email.  You can opt-out of these emails anytime by clicking unsubscribe at the bottom of the email.
        </UI.Legal>
        <UI.Actions>
          <Button type="submit" disabled={loading}>Agree and Apply</Button>
          <Button onClick={goBack}>Back</Button>
        </UI.Actions>
      </Form>
    </React.Fragment>
  );
};

export default Apply;

