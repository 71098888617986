import React, { useRef } from "react";
import { buildCldPoster, buildVideoSources } from "./cloudinary.js";

import * as UI from "./ui";

const Video = ({ videoConfig, posterOnly, ...rest }) => {
  const videoRef = useRef(null);

  const cldPoster = buildCldPoster(videoConfig);
  const cldVideoSources = buildVideoSources(videoConfig);

  return (
    <UI.Layout>
      <UI.Video
        cldPoster={cldPoster}
        innerRef={videoRef}
        preload="auto"
        sources={[]}
        {...rest}
      >
        {!posterOnly && cldVideoSources.map(({ type, src }) => (
          <source key={type} type={type} src={src} />
        ))}
      </UI.Video>
    </UI.Layout>
  );
}

export default Video;
