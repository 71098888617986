import styled from "styled-components";

export const Layout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const CardLayout = styled.div`
  pointer-events: none;
  user-select: none;
`;

export const EditorLayout = styled.div`
  max-width: 980px;
`;

export const Caption = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.875em;
  padding: 0 6px 6px;
  color: #333333;
`;

export const Instructions = styled.div`
  h2 {
    margin-bottom: 12px;
    font-size: 1.125em;
    font-weight: bold;
  }

  p {
    margin-bottom: 12px;
    font-size: 0.875em;
    line-height: 1.25em;
  }

  strong {
    font-weight: 700;
  }
`;
