import React, { useContext, useState } from "react";

import { combineValidations, presence, lengthOf } from "../../../../lib/validations";
import Formation from "../../../forms/Form";
import TextField from "../../../forms/TextField";
import ImageUploadField from "../../../forms/ImageUploadField";
import Button from "../../../design/Button";
import { Context } from "./";

const slidePreview = {
  aspectRatio: "16:9",
  width: "600",
  fetchFormat: "auto",
  style: {
    maxWidth: '600px',
    width: '100%',
  },
};

const validations = {
  caption: combineValidations([presence, lengthOf({ maximum: 180 })]),
};

const Form = ({ initialInputObject, onCancel, onSubmit, loading, type, id, label, cropOptions }) => {
  const { sweepstakes } = useContext(Context);
  const [uploading, setUploading] = useState(false);

  return (
    <Formation initialInputObject={initialInputObject} onSubmit={onSubmit} validations={validations}>
      <ImageUploadField
        id={id}
        label={label}
        owner={sweepstakes.id}
        previewProps={slidePreview}
        crops={cropOptions}
        directory="slides"
        type={type}
        onUploading={setUploading}
      />
      <TextField id="caption" label="Caption" />
      <Button size="small" onClick={onCancel}>Cancel</Button>
      <Button size="small" type="submit" disabled={uploading || loading}>Done</Button>
    </Formation>
  );
}

export default Form;
