import styled from 'styled-components';
import { default as DesignLink }  from "../../design/Link";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0;
  width: 100%;
  position: relative;
`;

export const Input = styled.input`
  font-family: inherit;
  font-size: 0.875em;
  padding: 0.5em;
  padding: 8px 12px 8px 12px;
  margin-bottom: 1em;
  border: 1px solid #999999;
  border-radius: 3px;
  outline: none;
  width: 100%;
  flex: 1;
`;

export const ResultsLayout = styled.div`
  position: absolute;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 10px;
  z-index: 1;
  top: 56px;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow: scroll;
`

export const Result = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  margin: 0.5em 0;

  &:first-child, &:last-child {
    margin: 0;
  }
`;

export const Title = styled.div`
  font-size: 0.875em;
  line-height: 1.5em;
  font-weight: bold;
  flex: 1;
  color: #333333;
  overflow-x: hidden;
  padding-right: 0.5em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Subtitle = styled.div`
  color: #999999;
  font-size: 0.875em;
  line-height: 1.5em;
  verticle-align: middle;
`;

export const Link = styled(DesignLink).attrs({ size: "small" })`
  display: inline-block;
  cursor: pointer;
`;

export const EmptyState = styled.div`
`;
