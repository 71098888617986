import React, { useState } from "react";

import NonprofitSearch from "./NonprofitSearch";
import ApplyForm from "./ApplyForm";
import SectionHeader from "../design/SectionHeader";
import ModeSwitch from "../../lib/ModeSwitch";

import * as UI from "./ui";

const ModeItem = ({ instructions, setMode, children }) => {
  const element = React.Children.only(children);

  return (
    <React.Fragment>
      <UI.Welcome>{instructions}</UI.Welcome>
      {React.cloneElement(element, { setMode })}
    </React.Fragment>
  );
};

const Apply = () => {
  const [selectedNonprofit, setSelectedNonprofit] = useState(null);

  const clearNonprofit = () => setSelectedNonprofit(null);

  return (
    <React.Fragment>
      <SectionHeader heading="Apply for a TapKat Nonprofit Account" />
      <ModeSwitch initialMode="search">
        <ModeItem mode="search" instructions="Welcome! Get started by searching for your Nonprofit Organization">
          <NonprofitSearch setNonprofit={setSelectedNonprofit} />
        </ModeItem>
        <ModeItem mode="form" instructions="Thanks for that! Now provide us a few more details.">
          <ApplyForm clearNonprofit={clearNonprofit} selectedNonprofit={selectedNonprofit} />
        </ModeItem>
      </ModeSwitch>
    </React.Fragment>
  );
};

export default Apply;

