import styled from "styled-components";

export const Layout = styled.div`
  padding: 24px;
  background-color: #edf6fb;
  border-radius: 12px;
  height: 100vh;

  @media screen and (min-width: 768px) and (min-height: 400px) {
    min-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset;
  }
`;

export const Header = styled.header`
  margin-bottom: 12px;
`;
